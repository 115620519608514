<template>
  <b-card border-variant="" body-class="px-2 py-2" class="mt-5 mb-5">
    <h4 class="fs-2x fw-bolder mb-0 text-center py-4" v-b-toggle="id">
      {{ configuration.title }}
      <i v-if="!isExpanded" class="fas fa-angle-double-down fa-md ml-2" title="Click to Expand"></i>
      <i v-if="isExpanded" class="fas fa-angle-double-up fa-md ml-2" title="Click to Collapse"></i>
    </h4>
    <b-collapse :id="id" v-model="isExpanded">
      <div class="row">
        <div v-for="(chunk, index) in dataChunks" :key="index" class="col-sm-6 table-responsive">
          <table class="table table-sm table-hover">
            <thead>
              <tr>
                <th width="45%">Name</th>
                <th width="25%" v-if="showCurrentValue">
                  {{ configuration.currentTitle }}
                </th>
                <th width="30%" v-if="showNewValue">
                  {{ configuration.renewalTitle }}
                </th>
                <th v-if="configuration.allowDeletion"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in chunk" v-bind:key="index">
                <td>
                  <label class="col-form-label ml-2" style="text-align: left">{{
                    item.displayName
                  }}</label>
                </td>
                <td v-if="showCurrentValue" class="py-2">
                  <label class="col-form-label" v-if="item.type === 'Text' ||
                    item.type === 'Picklist' ||
                    item.type === 'Integer' ||
                    item.type === 'Decimal' ||
                    item.type === 'Percent' ||
                    item.type === 'MultiLine-Text'
                    ">
                    {{ item.value }}
                  </label>
                  <label class="col-form-label" v-if="item.type === 'Currency' && item.value">
                    {{ item.value | currency("$", ",", 0, ".", "front", true) }}
                  </label>
                  <label v-if="item.type === 'Date'" class="col-form-label">
                    {{ item.value | dateParse | dateFormat }}
                  </label>
                </td>
                <td v-if="showNewValue" class="py-2">
                  <div v-b-tooltip.hover.left="item.newValue || 'no value'">
                    <input v-if="item.type === 'Text'" type="text" class="form-control" size="255" v-model="item.newValue"
                      :disabled="isReadOnly" />
                  </div>

                  <div v-b-tooltip.hover.left="item.newValue || 'no value'">
                    <textarea v-if="item.type === 'MultiLine-Text'" class="form-control min-rows" maxlength="2000"
                      v-model="item.newValue" :disabled="isReadOnly" :ref="'textarea_' + index"
                      @input="resizeTextarea(index)" />
                  </div>

                  <div v-b-tooltip.hover.left="item.newValue || 'no value'">
                    <input v-if="item.type === 'Integer' ||
                      item.type === 'Decimal' ||
                      item.type === 'Decimal'
                      " type="number" class="form-control" v-model="item.newValue" :disabled="isReadOnly" />
                  </div>

                  <div v-b-tooltip.hover.left="formattedValue(item.newValue)">
                    <currency-input v-if="item.type === 'Currency'" class="form-control" v-model="item.newValue"
                      :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree"
                      :precision="currencyConfig.precision" :disabled="isReadOnly" />
                  </div>

                  <div v-b-tooltip.hover.left="formatTooltipDate(item.newValue)">
                    <b-form-datepicker v-if="item.type === 'Date'" class="form-control" v-model="item.newValue"
                      :locale="'en-AU'"
                      :date-format-options="{
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                      }" :disabled="isReadOnly" />
                  </div>

                  <div v-b-tooltip.hover.left="item.newValue || 'no value'">
                    <b-form-select v-if="item.type === 'Picklist'" class="form-control" v-model="item.newValue"
                      :disabled="isReadOnly">
                      <option value=""></option>
                      <option v-for="option in item.options" :key="option" :value="option">
                        {{ option }}
                      </option>
                    </b-form-select>
                  </div>
                </td>
                <td style="vertical-align: middle; background-color: white; border: none;">
                  <button class="btn p-0" @click.prevent="deleteData(item)" :disabled="isReadOnly"
                    v-if="configuration.allowDeletion && item.isCustomItem">
                    <i class="fa fa-minus-circle fa-1.5x text-danger" style="opacity: 0.75;"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="configuration.allowAddition && !isReadOnly" class="mt-10">
        <span class="font-weight-bold">
          To add a new entry, enter data in the fields below and click '+ Add'
        </span>
        <div class="row mt-5 mb-3 align-items-center">
          <div class="col-2">
            <input type="text" class="form-control" placeholder="Enter Name" v-model="objAddData.displayName"
              :disabled="isReadOnly" />
          </div>
          <div class="col-2">
            <b-form-select v-model="objAddData.type" :disabled="isReadOnly">
              <option value="">Select Type</option>
              <option value="Text">Text</option>
              <option value="Currency">Currency</option>
              <option value="Integer">Integer</option>
              <option value="Decimal">Decimal</option>
              <option value="Percent">Percent</option>
            </b-form-select>
          </div>
          <div class="col-2">
            <input v-if="!objAddData.type || objAddData.type === 'Text'" type="text" class="form-control" size="255"
              placeholder="Enter Value" v-model="objAddData.newValue" :disabled="isReadOnly" />
            <input v-if="objAddData.type === 'Integer' ||
              objAddData.type === 'Decimal' ||
              objAddData.type === 'Percent'
              " type="number" class="form-control" v-model="objAddData.newValue" :disabled="isReadOnly" />
            <currency-input v-if="objAddData.type === 'Currency'" class="form-control" v-model="objAddData.newValue"
              :allow-negative="currencyConfig.allowNegative" :distraction-free="currencyConfig.distractionFree"
              :precision="currencyConfig.precision" :disabled="isReadOnly" />
          </div>
          <div class="col-auto d-flex align-items-center" v-if="configuration.allowAddition">
            <button class="btn btn-sm btn-outline-primary" @click.prevent="addData" :disabled="isReadOnly">
              <i class="fas fa-plus fs-2x"></i>
              {{ configuration.addButtonTitle }} Add
            </button>
          </div>
        </div>

        <p class="text-danger" v-if="fieldsIncomplete">All fields must be completed before adding.</p>
      </div>
    </b-collapse>
  </b-card>
</template>

<style>
select {
  word-wrap: normal;
}
/* Custom CSS to override Bootstrap disabled form-select styling */
.form-control:disabled, .form-control[readonly] {
  background-color: #eff3f6;
}
/* Custom CSS for disabled date picker */
.b-form-btn-label-control.form-control[aria-disabled="true"], .b-form-btn-label-control.form-control[aria-readonly="true"] {
  background-color: #eff3f6;
}
.form-control.min-rows {
  min-height: calc(1.5em * 3 + 2px);
}
</style>

<script>
// @ts-ignore
import _ from "lodash";

export default {
  name: "DeclarationForm",
  props: {
    configuration: {
        type: Object,
        default: () => ({
          title: '',
          currentTitle: '',
          renewalTitle: '',
          allowAddition: false,
          allowDeletion: false,
          addButtonTitle: '',
        })
      },
    formData: Array,
    isReadOnly: Boolean,
  },
  computed: {
    dataChunks: function () {
      if (Array.isArray(this.formData) && this.formData.length > 0) {
        return _.chunk(this.formData, Math.ceil(this.formData.length / 2));
      }
      return [];
    },
    id: function () {
      if (this.configuration && this.configuration.title) {
        return this.configuration.title.split(" ").join("_");
      }
      return "";
    },
    showCurrentValue: function () {
      if (this.configuration && this.configuration.showCurrentValue === false) {
        return true;
      }
      return true;
    },
    showNewValue: function () {
      if (this.configuration && this.configuration.showNewValue === false) {
        return false;
      }
      return true;
    },
  },
  methods: {
    addData: function () {
      if (
        !this.objAddData.displayName ||
        !this.objAddData.type ||
        !this.objAddData.newValue
      ) {
        this.fieldsIncomplete = true;
        return;
      }
      this.fieldsIncomplete = false;
      let apiKey = this.generateApiKey(this.objAddData.displayName);

      let keyExists = _.some(this.formData, { apiKey: apiKey });
      let suffix = 2;
      while (keyExists) {
        apiKey = apiKey =
          this.generateApiKey(this.objAddData.displayName) + String(suffix);
        keyExists = _.some(this.formData, { apiKey: apiKey });
        ++suffix;
      }
      this.$set(this.objAddData, "apiKey", apiKey);
      this.formData.push({ ...this.objAddData, isCustomItem: true });
      this.objAddData = {};
    },

    deleteData: function (item) {
      if (item.isCustomItem) {
        this.$emit('delete-item', item, this.formData); //emit the form data as well
      }
    },

    generateApiKey: function (displayName) {
      let apiKey = displayName.trim().replace(/[^a-zA-Z0-9]/g, "");
      apiKey = _.camelCase(apiKey);
      return apiKey;
    },

    resizeTextarea() {
      this.$nextTick(() => {
        const textareas = document.querySelectorAll('textarea');
        textareas.forEach((textarea) => {
          textarea.style.height = 'auto';
          textarea.style.height = (textarea.scrollHeight + 4) + 'px';
        });
      });
    },
    formattedValue(value) {
      if (value) {
        return parseFloat(value).toLocaleString(undefined, {
          style: 'currency',
          currency: 'AUD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        });
      }
      return 'no value';
    },

    formatTooltipDate(date) {
      if (!date) return 'no value';
      const formattedDate = new Date(date).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
      return formattedDate;
    },

  },

  data() {
    return {
      isExpanded: false,
      objAddData: {},
      textareaRefs: [],
      fieldsIncomplete: false,
      currencyConfig: {
        allowNegative: false,
        distractionFree: false,
        precision: 0,
      },
    };
  },

  updated() {
    this.resizeTextarea();
  },

  mounted() {
    this.$nextTick(() => {
      // Allow a short delay before resizing
      setTimeout(() => {
        this.resizeTextarea();
      }, 100);
    });
  },
};
</script>
